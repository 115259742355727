@keyframes pulse {
    from {
        transform: scale(1);
        opacity: 0.4;
    }

    to {
        transform: scale(1.4);
        opacity: 0; 
    }
}