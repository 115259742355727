@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.4;
  }
  to {
    transform: scale(1.4);
    opacity: 0;
  }
}
.subpage-title {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 0 1em;
  text-transform: uppercase;
  font-size: 4.5rem;
  text-transform: none;
  color: #000;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  text-align: center;
  width: fit-content;
  z-index: 2;
}

.extra-span .error {
  font-size: 3.5rem;
}

.error {
  color: #f0f6f6;
}
.error span {
  background: linear-gradient(90deg, #85b4fb, #b2e9ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.error-title {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-left: -1em;
  margin: 0 1rem;
  font-weight: 500;
  line-height: 1.4em;
}

.slim-wrapper {
  margin: 0 auto;
  max-width: 850px;
  width: 100%;
}

.hosting-services {
  background-color: #fff;
  position: relative;
  padding-top: 11em;
}
.hosting-services h2 {
  font-weight: 400;
  font-size: 3rem;
  text-align: center;
}
.hosting-services .service-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 1fr);
  column-gap: 1em;
  row-gap: 1em;
}
.hosting-services .service-grid .service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0 3em;
  padding: 1em;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s;
  z-index: 20;
}
.hosting-services .service-grid .service:hover {
  transform: translateY(-5%);
}
.hosting-services .service-grid .service h3 {
  margin-bottom: 0.7em;
  font-family: "Poppins", sans-serif;
  color: #0185BC;
}
.hosting-services .service-grid .service .service-text {
  color: #444;
  font-family: "Poppins", sans-serif;
}
.hosting-services .service-info {
  margin: 4em 0;
  text-align: center;
  color: #444;
  overflow: visible;
}
.hosting-services .service-info p {
  font-size: 2.3rem;
  margin: 0 1.5em;
}

.intro {
  text-align: center;
  color: #444;
  font-size: 1.5rem;
  margin: -2em 1.5em 3em 1.5em;
}

.websides-header,
.hosting-header,
.positioning-header,
.it-support-header,
.responsive-header,
.about-us-header,
.blog-header,
.cms-header,
.portfolio-header {
  position: relative;
  height: 55vh;
  background-position: center;
  background-size: cover;
}
.websides-header__text,
.hosting-header__text,
.positioning-header__text,
.it-support-header__text,
.responsive-header__text,
.about-us-header__text,
.blog-header__text,
.cms-header__text,
.portfolio-header__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.websides-header__text,
.hosting-header__text,
.positioning-header__text,
.it-support-header__text,
.responsive-header__text,
.about-us-header__text,
.blog-header__text,
.cms-header__text,
.portfolio-header__text {
  display: inline-block;
  font-size: 2rem;
  font-weight: normal;
  z-index: 2;
}
.websides-header__text .company-name,
.hosting-header__text .company-name,
.positioning-header__text .company-name,
.it-support-header__text .company-name,
.responsive-header__text .company-name,
.about-us-header__text .company-name,
.blog-header__text .company-name,
.cms-header__text .company-name,
.portfolio-header__text .company-name {
  font-family: "Poppins", sans-serif;
  background: linear-gradient(90deg, #00a3e0, #005f8a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.2em;
  font-size: 3.45rem;
}
.websides-header__text h1,
.hosting-header__text h1,
.positioning-header__text h1,
.it-support-header__text h1,
.responsive-header__text h1,
.about-us-header__text h1,
.blog-header__text h1,
.cms-header__text h1,
.portfolio-header__text h1 {
  font-size: 2.2rem;
  font-weight: 700;
  color: #f0f6f6;
}
.websides-header__text h1 span,
.hosting-header__text h1 span,
.positioning-header__text h1 span,
.it-support-header__text h1 span,
.responsive-header__text h1 span,
.about-us-header__text h1 span,
.blog-header__text h1 span,
.cms-header__text h1 span,
.portfolio-header__text h1 span {
  background: linear-gradient(90deg, #85b4fb, #b2e9ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.websides-header {
  background-image: url("/dist/img/work-two-small.jpg");
}

.hosting-header {
  background-image: url("/dist/img/work-small.jpg");
}

.positioning-header {
  background-image: url("/dist/img/meeting-medium.jpg");
}

.it-support-header {
  background-image: url("/dist/img/coding-924920_640.avif");
}

.responsive-header {
  background-position: bottom;
  background-image: url("/dist/img/team-small.jpg");
}

.about-us-header {
  background-position: -20em -11em;
  background-image: url("/dist/img/abt-us-small.jpg");
}

.blog-header {
  height: 40vh;
  background: linear-gradient(120deg, rgba(0, 34, 56, 0.98), rgba(0, 0, 0, 0.98));
}
.blog-header .company-name {
  position: absolute;
  transform: translate(-50%, -50%);
  text-transform: none;
  font-size: 2.1rem;
  width: 60vw;
  font-weight: 500;
  color: #f0f6f6;
  display: inline-block;
}

.portfolio-header {
  background-position: center;
  background-image: url("/dist/img/subpage-portfolio-bg-medium.jpg");
}
.portfolio-header .nav--scrolled {
  background: rgba(0, 0, 0, 0.7);
}

#margin-botton-portfolio {
  margin-bottom: 7em;
}

.cms-header {
  height: 10vh;
}
.cms-header .nav--scrolled {
  background: linear-gradient(120deg, rgba(0, 14, 28, 0.98), rgba(28, 0, 0, 0.92));
}

#contact-margin-blog {
  margin-top: 7em;
}
#contact-margin-blog .agency__button {
  left: 48%;
  bottom: 4em;
  transform: translateX(-50%);
}

.blog-img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2em;
  width: 80%;
  border-radius: 2px;
  height: 300px;
  object-fit: cover;
}

.cards {
  display: grid;
  grid-template-columns: 1fr;
  border-top: 1px solid #939393;
}
.cards a {
  color: #00529e;
}
.cards__welcome {
  position: relative;
  text-align: center;
  padding-top: 4em;
  line-height: 2.5rem;
}
.cards__welcome-heading {
  color: #0185BC;
  font-family: "Montserrat", sans-serif;
  font-size: 3.5rem;
  line-height: 1.4em;
  margin: 0 0.5em;
  margin-bottom: 1rem;
  font-weight: 500;
}
.cards__welcome-text {
  font-family: "Montserrat", sans-serif;
  margin-top: 2em;
  font-size: 2rem;
  color: #555;
}
.cards .card {
  padding: 0 1.1em;
}
.cards .card img {
  margin-top: 3em;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.cards .card__title {
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5em;
}
.cards .card p {
  font-family: "Montserrat", sans-serif;
  line-height: 1.4em;
  color: #333;
  cursor: pointer;
}
.cards .card:hover p {
  text-decoration: underline;
}

.p-none {
  display: none;
}

.webside-box .websides-text {
  font-family: "Montserrat", sans-serif;
  color: #555;
  font-size: 1.7rem;
  line-height: 1.5em;
}
.webside-box .agency__button {
  position: relative;
  top: 2em;
  left: 50%;
  transform: translateX(-58%);
}

.subpage-accordion {
  background-color: #fff;
  border-top: 1px solid #939393;
}
.subpage-accordion .subpage-accordion {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  overflow: hidden;
}
.subpage-accordion .subpage-accordion-item {
  border-bottom: 1px solid #e1e1e1;
}
.subpage-accordion .subpage-accordion-item:last-child {
  border-bottom: none;
}
.subpage-accordion .subpage-accordion-item.active .subpage-accordion-content {
  padding: 15px 20px;
}
.subpage-accordion .subpage-accordion-header {
  width: 100%;
  background: none;
  padding: 15px 20px;
  font-family: "Poppins", sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: left;
  transition: background 0.3s, color 0.3s;
}
.subpage-accordion .subpage-accordion-header span {
  transition: color 0.3s;
}
.subpage-accordion .subpage-accordion-header:hover {
  background: #e1e1e1;
}
.subpage-accordion .subpage-accordion-header img {
  transition: transform 0.5s ease;
  filter: invert(0);
}
.subpage-accordion .subpage-accordion-content {
  background: none;
  padding: 0 20px;
  font-family: "Montserrat", sans-serif;
  color: #444;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, padding 0.5s ease-out;
  box-sizing: border-box;
}
.subpage-accordion .subpage-accordion-content span {
  font-weight: 600;
}
.subpage-accordion .subpage-accordion-item.active .subpage-accordion-content {
  max-height: 500px;
}
.subpage-accordion .subpage-accordion-item.active .subpage-accordion-header img {
  transform: rotate(180deg);
}
.subpage-accordion .subpage-accordion-item.active .subpage-accordion-header span {
  color: #0185BC;
}

.subpage-portfolio {
  background-color: #f0f6f6;
  border-bottom: 1px solid #939393;
}
.subpage-portfolio .projects {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 20px;
}
.subpage-portfolio .projects .project {
  display: flex;
  position: relative;
  background-color: #fff;
  border: 1px solid #555;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}
.subpage-portfolio .projects .project:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}
.subpage-portfolio .projects .project .project-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: opacity 0.3s;
  cursor: zoom-in;
}
.subpage-portfolio .projects .project .project-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}
.subpage-portfolio .projects .project .project-overlay .project-name {
  font-size: 1.25rem;
  margin-bottom: 10px;
}
.subpage-portfolio .projects .project .project-overlay .link-box {
  display: flex;
}
.subpage-portfolio .projects .project .project-overlay .project-link {
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  margin: 0 auto;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  transition: transform 0.3s;
}
.subpage-portfolio .projects .project .project-overlay .project-link:hover {
  transform: translateY(-2px);
}
.subpage-portfolio .projects .project:hover .project-overlay {
  opacity: 1;
}
.subpage-portfolio .projects .project-none-sm {
  display: none;
}

.reasons-heading {
  text-align: center;
  color: #2c3e50;
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 30px;
}
.reasons-heading .span {
  font-weight: 700;
  font-size: 4rem;
  background: linear-gradient(90deg, #00a3e0, #005f8a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.reason-h4 {
  color: #2c3e50;
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  margin-top: 2em;
}

.section-photo {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  width: 1000px;
  height: 400px;
}

.reason-text .reason-li {
  margin: 3.2em 0;
}

.reason,
.write-to-us {
  margin-bottom: 30px;
  padding: 20px;
}
.reason h3,
.write-to-us h3 {
  font-family: "Poppins", sans-serif;
  color: #1b2630;
  font-weight: 500;
  font-size: 2.5rem;
  margin-bottom: 15px;
}
.reason-text,
.write-to-us-text {
  font-family: "Montserrat", sans-serif;
  color: #555;
  line-height: 1.8;
  font-size: 16px;
}

.write-to-us {
  text-align: center;
}
.write-to-us a {
  color: #0185BC;
  text-decoration: underline;
}

.extra-span {
  font-weight: 600;
  background: linear-gradient(90deg, #00a3e0, #005f8a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

span {
  color: #444;
  font-weight: 600;
}

.more {
  position: relative;
  background-image: url("/dist/img/luca-bravo-small.jpg");
  background-size: cover;
}
.more img {
  z-index: 2;
}
.more .options {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  text-align: center;
  z-index: 2;
}
.more .options .more-option {
  padding: 0 3em;
  z-index: 2;
}
.more .options .more-option img {
  margin: 1em 0;
}
.more .options .more-option--title {
  color: #f0f6f6;
  margin-bottom: 0.5em;
}
.more .options .more-option--text {
  color: #f0f6f6;
}
.more .card__button {
  border: 2px solid #fff;
  color: #fff;
  transition: background-color 1s;
}
.more .card__button:hover {
  background-color: #70b5ff;
  border-color: #70b5ff;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 162, 255, 0.4);
}

.contact {
  border-top: 1px solid #939393;
}

@media (min-width: 600px) {
  .cards-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .blog-header .company-name {
    font-size: 2.5rem;
  }
}
@media (min-width: 767px) {
  .error {
    font-size: 6rem;
  }
  .error-title {
    font-size: 3rem;
  }
  .websides-header {
    background-image: url("/dist/img/work-two-big.jpg");
  }
  .hosting-header {
    background-image: url("/dist/img/work-big.jpg");
  }
  .positioning-header {
    background-image: url("/dist/img/meeting-big.jpg");
  }
  .it-support-header {
    background-image: url("/dist/img/coding-big.jpg");
  }
  .responsive-header {
    background-image: url("/dist/img/team-big.jpg");
  }
  .about-us-header {
    background-image: url("/dist/img/abt-us-big.jpg");
    background-position: 0 37em;
  }
  .blog-header .company-name {
    font-size: 2.9rem;
  }
  .p-none {
    display: block;
  }
  .subpage-underline {
    height: 11.5%;
  }
  .subpage-underline .hamburger {
    right: 100px;
  }
  .subpage-underline .hamburger--emphatic .hamburger-box {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 24px;
  }
  .subpage-underline .hamburger--emphatic .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    transition: transform 0.3s ease, background-color 0.3s ease, top 0.3s ease;
    position: absolute;
    width: 55px;
    height: 3px;
    border-radius: 4px;
    background-color: #8ACEF2;
  }
  .subpage-underline .hamburger--emphatic .hamburger-inner::before, .subpage-underline .hamburger--emphatic .hamburger-inner::after {
    width: 35px;
    height: 3px;
  }
  .subpage-underline .hamburger--emphatic .hamburger-inner::before {
    top: -14px;
  }
  .subpage-underline .hamburger--emphatic .hamburger-inner::after {
    bottom: -14px;
    right: 0;
  }
  .subpage-underline .hamburger.is-active .hamburger-inner::before, .subpage-underline .hamburger.is-active .hamburger-inner::after {
    width: 55px;
  }
  .subpage-underline .logo {
    top: 50%;
    left: 50px;
  }
  .hamburger:hover.hamburger--emphatic .hamburger-inner::before {
    width: 55px;
  }
  .hamburger:hover.hamburger--emphatic .hamburger-inner::after {
    width: 55px;
  }
  .hosting-services .service-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 1em;
    row-gap: 1em;
  }
  .hosting-services .service-grid .service {
    margin: 0 0;
  }
  .intro {
    font-size: 1.6rem;
  }
  .reasons-heading {
    font-size: 3.5rem;
  }
  .subpage-portfolio .projects {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .more {
    background-image: url("/dist/img/luca-bravo-big.jpg");
  }
  .more .options {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
}
@media (min-width: 992px) {
  .websides-header,
  .hosting-header,
  .positioning-header,
  .it-support-header,
  .responsive-header,
  .about-us-header,
  .blog-header,
  .cms-header,
  .portfolio-header,
  .more {
    background-attachment: fixed;
  }
  .hosting-header__text p span {
    font-size: 3.4rem;
  }
  .blog-header .company-name {
    font-size: 4rem;
  }
  .subpage-underline {
    height: 10%;
    transition: background-color 0.3s;
  }
  .subpage-underline .hamburger,
  .subpage-underline .hamburger--emphatic {
    display: none;
  }
  .subpage-underline .logo {
    left: 10px;
  }
}
@media (min-width: 1024px) {
  .cards-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-padding-top: 81.2px;
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem;
}

section {
  overflow: hidden;
}

a {
  text-decoration: none;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.extra-wrapper {
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
}

.slide-in {
  opacity: 0.7;
  transform: translateY(85px);
  transition: opacity 2.2s ease-out, transform 1.4s ease-out;
}

.slide-in.show {
  opacity: 1;
  transform: translateY(0);
}

.nav-scrolled {
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.3s ease-in-out;
}

.section-title {
  position: relative;
  font-size: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 0 2em;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  background: linear-gradient(90deg, #00a3e0, #005f8a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-weight: 300;
  width: fit-content;
}

.section {
  padding: 6em 2em;
}

.hero-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.header {
  position: relative;
  height: 100vh;
  background-image: url("/dist/img/header-img_11zon.jpg");
  background-position: center;
  background-size: cover;
}
.header__text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.header__text {
  display: inline-block;
  font-size: 2rem;
  font-weight: normal;
  z-index: 2;
}
.header__text .company-name {
  font-family: "Poppins", sans-serif;
  background: linear-gradient(90deg, #00a3e0, #005f8a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.2em;
  font-size: 4rem;
}
.header__text h1 {
  font-size: 2.2rem;
  font-weight: 700;
  color: #f0f6f6;
}
.header__text h1 span {
  background: linear-gradient(90deg, #85b4fb, #b2e9ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}
.header__arrow {
  position: absolute;
  bottom: 5em;
  left: 50%;
  padding: 2.1em;
  transform: translateX(-50%);
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 30;
}
.header__arrow::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  animation: pulse 2s infinite;
}
.header__arrow-icon {
  display: block;
  width: 30px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.mobile-underline {
  position: fixed;
  height: 81.2px;
  width: 100%;
  z-index: 20000;
  transition: 0.3s;
}
.mobile-underline .hamburger {
  position: absolute;
  padding: 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  display: inline-block;
  cursor: pointer;
  z-index: 1000;
  -webkit-touch-callout: none;
  user-select: none;
  background: none;
  border: none;
  z-index: 2000;
}
.mobile-underline .hamburger--emphatic .hamburger-box {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 24px;
}
.mobile-underline .hamburger--emphatic .hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  transition: transform 0.3s ease, background-color 0.3s ease, top 0.3s ease;
  position: absolute;
  width: 40px;
  height: 3px;
  border-radius: 4px;
  background-color: #8ACEF2;
}
.mobile-underline .hamburger--emphatic .hamburger-inner::before, .mobile-underline .hamburger--emphatic .hamburger-inner::after {
  content: "";
  display: block;
  background-color: #8ACEF2;
  transition: transform 0.3s ease, top 0.3s ease, opacity 0.3s ease;
  position: absolute;
  width: 40px;
  height: 3px;
  border-radius: 4px;
  transition: 0.3s;
}
.mobile-underline .hamburger--emphatic .hamburger-inner::before {
  top: -13.5px;
}
.mobile-underline .hamburger--emphatic .hamburger-inner::after {
  bottom: -13.5px;
  right: 0;
}
.mobile-underline .hamburger.is-active .hamburger-inner {
  background-color: transparent;
}
.mobile-underline .hamburger.is-active .hamburger-inner::before {
  top: 0;
  width: 40px;
  transform: rotate(45deg);
}
.mobile-underline .hamburger.is-active .hamburger-inner::after {
  top: 0;
  width: 40px;
  transform: rotate(-45deg);
}
.mobile-underline .logo {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: scale(0.7);
  transform: translateY(-50%);
  z-index: 2000;
}

.hamburger:hover.hamburger--emphatic .hamburger-inner::before {
  width: 40px;
}

.hamburger:hover.hamburger--emphatic .hamburger-inner::after {
  width: 40px;
}

.nav-mobile {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(120deg, rgba(0, 14, 28, 0.98), rgba(17, 0, 0, 0.98));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transform: translateX(102%);
  transition: transform 0.3s;
  z-index: 1000;
}
.nav-mobile .nav__link {
  display: block;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  color: #f0f6f6;
  display: inline-block;
  padding: 0.7em 0;
  font-size: 2rem;
  transition: color 0.3s ease;
}
.nav-mobile .nav__link:hover {
  color: #8ACEF2;
}
.nav-mobile .nav__translate {
  padding: 1em 2em;
}
.nav-mobile--active {
  transform: translateX(0);
}
.nav-desktop {
  display: none;
}

.translate-box {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.8em;
  bottom: 0.2em;
}

.goog-te-gadget-icon {
  display: none;
}

.goog-te-gadget-simple {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
  font-family: "Montserrat", sans-serif;
  color: #f5f5f5;
  font-weight: 400;
}

.nav__flags {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.nav__flags .flag-button {
  cursor: pointer;
  margin-left: 10px;
  width: 32px;
  height: 32px;
  align-items: center;
}

.agency {
  padding: 5em 2em;
}
.agency__text {
  position: relative;
  text-align: center;
  padding-bottom: 6rem;
  line-height: 2.5rem;
}
.agency__text .agency-title {
  font-family: "Montserrat", sans-serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-left: -1em;
  font-weight: 500;
}
.agency__text .agency-title .congrats {
  font-size: 4rem;
  line-height: 1.5em;
  font-weight: 700;
}
.agency__text-p {
  font-size: 1.5rem;
  color: #555;
}
.agency__text-p .agency-p-span {
  font-weight: 700;
}
.agency .cards-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.agency .cards-box .card {
  text-align: center;
  margin: 3rem 0;
  flex: 1;
}
.agency .cards-box .card__title {
  margin: 1rem 0;
  font-size: 2.5rem;
}
.agency .cards-box .card p {
  color: #555;
}

.agency__button {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin: 20px;
  padding: 1em 2em;
  background: none;
  border: 2px solid #0185BC;
  color: #0185BC;
  font-size: 1.3rem;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;
}

.agency__button::before {
  position: absolute;
  content: "";
  top: -30px;
  left: -130px;
  height: 260%;
  width: 100px;
  background: linear-gradient(90deg, rgba(0, 163, 224, 0.8), rgba(0, 95, 138, 0.1));
  transform: rotate(20deg);
  transition: 0.25s;
}

.agency__button:hover::before {
  left: 130%;
}

.card__button {
  margin-top: 2em;
  padding: 0.7em 3em;
  border: 2px solid #2991ff;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #2991ff;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border-radius: 50px;
  z-index: 1;
}
.card__button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #70b5ff, #00a2ff);
  transition: transform 0.3s ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
  z-index: -1;
  border-radius: 50px;
}
.card__button:hover {
  color: #fff;
  border-color: transparent;
  box-shadow: 0 0 10px rgba(0, 162, 255, 0.5);
}
.card__button:hover::before {
  transform: scaleX(1);
}
.card__button:active {
  transform: scale(0.95);
  box-shadow: 0 3px 10px rgba(0, 162, 255, 0.6);
}

.accordion {
  background-color: #f0f6f6;
}
.accordion .section-title {
  text-transform: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #161616;
  display: inline-block;
  text-align: center;
}
.accordion .accordion {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
  overflow: hidden;
}
.accordion .accordion-item {
  border-bottom: 1px solid #555;
}
.accordion .accordion-item:last-child {
  border-bottom: none;
}
.accordion .accordion-item.active .accordion-content {
  padding: 15px 20px;
}
.accordion .accordion-header {
  width: 100%;
  background: none;
  padding: 15px 20px;
  font-family: "Poppins", sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: left;
  transition: background 0.3s, color 0.3s;
}
.accordion .accordion-header span {
  transition: color 0.3s;
}
.accordion .accordion-header:hover {
  background: #e1e1e1;
}
.accordion .accordion-header img {
  transition: transform 0.3s ease;
  filter: invert(0);
}
.accordion .accordion-content {
  background: none;
  padding: 0 20px;
  font-family: "Montserrat", sans-serif;
  color: #444;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  box-sizing: border-box;
}
.accordion .accordion-content p {
  line-height: 1.5em;
}
.accordion .accordion-item.active .accordion-content {
  max-height: 500px;
}
.accordion .accordion-item.active .accordion-header img {
  transform: rotate(180deg);
}
.accordion .accordion-item.active .accordion-header span {
  color: #0185BC;
}
.accordion__text {
  font-family: "Poppins", sans-serif;
  margin-top: 4em;
  text-align: center;
}
.accordion__text-heading {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 0.5em;
}
.accordion__text-p {
  line-height: 1.6em;
}

.offer {
  background-color: #fff;
}
.offer .offer-box {
  display: grid;
  gap: 1.5em;
}
.offer .offer-box .offer-card {
  text-align: center;
  padding: 1em;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s;
}
.offer .offer-box .offer-card:hover {
  transform: scale(0.95);
}
.offer .offer-box .offer-card img {
  max-width: 60px;
  margin-bottom: 0.5em;
}
.offer .offer-box .offer-card__title {
  font-size: 1.2em;
  margin-bottom: 0.5em;
  color: #0185BC;
}
.offer .offer-box .offer-card p {
  font-size: 1em;
  color: #555;
}

.achievments {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
  background-image: url("/dist/img/achievments-medium.jpg");
  width: 100%;
  background-position: center;
  background-size: cover;
}
.achievments__box {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1em 0;
}
.achievments__box .achievment {
  font-family: "Poppins", sans-serif;
  font-size: 4.5rem;
  background: linear-gradient(90deg, #00a3e0, #005f8a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  margin-bottom: -0.1em;
  z-index: 2;
}
.achievments__box--text {
  font-family: "Montserrat", sans-serif;
  font-size: 2.5rem;
  margin: 1em;
  font-weight: 700;
  color: #fff;
  z-index: 2;
}
.achievments .dark-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.portfolio {
  background-color: #f0f6f6;
}
.portfolio__text {
  position: relative;
  text-align: center;
  font-weight: 500;
  bottom: 2em;
  font-size: 2.5rem;
  color: #4a4a4a;
}
.portfolio .filter-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}
.portfolio .filter-buttons .filter-btn {
  padding: 1em 1.5em;
  background-color: transparent;
  border: 2px solid #0185BC;
  cursor: pointer;
  color: #0185BC;
  font-size: 1.3rem;
  transition: transform 0.3s;
}
.portfolio .filter-buttons .filter-btn:hover {
  transform: scale(0.95);
}
.portfolio .projects {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 20px;
}
.portfolio .projects .project {
  display: flex;
  position: relative;
  background-color: #fff;
  border: 1px solid #a3a3a3;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}
.portfolio .projects .project:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}
.portfolio .projects .project .project-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: opacity 0.3s;
  cursor: zoom-in;
}
.portfolio .projects .project .project-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 20px;
  text-align: center;
  transform: translateY(100%);
  transition: transform 0.3s;
  border-top: 2px solid #8ACEF2;
}
.portfolio .projects .project .project-overlay .project-name {
  font-size: 1.25rem;
  margin-bottom: 10px;
}
.portfolio .projects .project .project-overlay .link-box {
  display: flex;
}
.portfolio .projects .project .project-overlay .project-link {
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  margin: 0 auto;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  transition: transform 0.3s;
}
.portfolio .projects .project .project-overlay .project-link:hover {
  transform: translateY(-2px);
}
.portfolio .projects .project:hover .project-overlay {
  transform: translateY(0);
}
.portfolio .projects .project-none-sm {
  display: none;
}

.load-more {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5em;
  padding: 0.7em 3em;
  background-color: transparent;
  border: 2px solid #0185BC;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #0185BC;
  text-transform: uppercase;
  transition: color 0.3s, background-color 0.3s;
}
.load-more:hover {
  background-color: #0185BC;
  color: #fff;
}
.load-more:hover {
  background-color: #0185BC;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}
.modal-content {
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  position: relative;
  text-align: center;
}
.modal-content .close {
  opacity: 0;
}
.modal-content .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.modal-content .modal-body .prev,
.modal-content .modal-body .next {
  display: none;
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.7em 0.8em;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}
.modal-content .modal-body .prev img,
.modal-content .modal-body .next img {
  width: 3.4em;
  height: 3.4em;
}
.modal-content .modal-body .prev {
  left: 0;
  z-index: 1;
}
.modal-content .modal-body .prev img {
  transform: translate(-5%, 2%);
}
.modal-content .modal-body .next {
  right: 0;
}
.modal-content .modal-body .next img {
  transform: translate(5%, 2%);
}
.modal-content .modal-body .modal-images {
  max-width: 100%;
  max-height: 100%;
}
.modal-content .modal-body .modal-images img {
  width: 100%;
  height: auto;
  border-radius: 0.1em;
  transform: scale(1.01);
}

.reviews {
  padding: 3.2em 1em;
  border-top: 1px solid #939393;
}
.reviews__title {
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding-bottom: 1em;
  font-size: 3rem;
  font-weight: 400;
}

.contact {
  background-color: #f0f6f6;
}
.contact__text {
  font-family: "Poppins", sans-serif;
  color: #555;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 5em;
}
.contact__form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.contact__form-box label {
  display: block;
  margin-bottom: 0.5em;
  font-size: 2rem;
}
.contact__form-box textarea {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
}
.contact__form-box input,
.contact__form-box textarea {
  padding: 1em 2em;
  margin-bottom: 2em;
  background-color: #f5f5f5;
  width: 100%;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 1em;
}
.contact__form-box input:focus,
.contact__form-box textarea:focus {
  outline: none;
  border: 1px solid #25AAE1;
}
.contact__form-box:nth-child(1) {
  grid-column: 1/-1;
  grid-row: 1/2;
}
.contact__form-box:nth-child(2) {
  grid-column: 1/-1;
  grid-row: 2/3;
}
.contact__form-box:nth-child(3) {
  grid-column: 1/-1;
  grid-row: 3/4;
}
.contact .checkbox {
  grid-row: 4/5;
  grid-column: 1/-1;
  z-index: 1;
}
.contact .checkbox input {
  padding: 1em;
  cursor: pointer;
  margin-right: 1em;
}
.contact .checkbox label {
  font-family: "Poppins", sans-serif;
  line-height: 3rem;
  color: #555;
}
.contact__form-btn {
  grid-column: 1/3;
  grid-row: 6/7;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 2em;
  border: 1px solid #25AAE1;
  background: none;
  font-weight: bold;
  color: #25AAE1;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
.contact__form-btn p {
  display: flex;
  justify-content: center;
}
.contact__form-btn:hover, .contact__form-btn:focus {
  outline: none;
  color: #fff;
  background-color: #25AAE1;
}
.contact .side-info {
  grid-column: 1/-1;
  grid-row: 5/6;
  margin: 1em 0;
  text-align: center;
  color: #555;
}
.contact .side-info p {
  margin: 3em 0;
  line-height: 3rem;
}

.cookie-notification {
  position: fixed;
  bottom: 1em;
  left: 50%;
  transform: translatex(-50%);
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  padding: 0.8em 2em;
  border-radius: 0.3em;
  z-index: 1000;
  transition: transform 0.3s ease;
}
.cookie-notification.hidden {
  display: none;
}
.cookie-notification .cookie-content {
  max-width: 900px;
  text-align: center;
}
.cookie-notification .cookie-content p {
  margin: 10px 0 10px;
  font-size: 16px;
  color: #fff;
}
.cookie-notification .cookie-content .cookie-button {
  background: #0185BC;
  border: none;
  border-radius: 0.3em;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  padding: 10px 20px;
  margin: 0 1em;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.cookie-notification .cookie-content .cookie-button:hover {
  background: #0185BC;
}

.footer {
  background: linear-gradient(120deg, rgba(0, 14, 28, 0.98), rgba(17, 0, 0, 0.98));
}
.footer .wrapper {
  padding: 6em 2em 2em;
}
.footer__box {
  font-size: 1.4rem;
}
.footer__box-title {
  background: linear-gradient(90deg, #00a3e0, #005f8a);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  margin-bottom: 1em;
  font-size: 3rem;
  text-transform: uppercase;
}
.footer__box .contact-side-info .admin-text .nip {
  color: #fff;
  font-weight: 400;
}
.footer p {
  color: #fff;
}
.footer__box-desktop {
  display: none;
}
.footer__box-social {
  margin: 2em 1em;
}
.footer__box-link {
  padding: 0 0.3em;
  font-size: 3.6rem;
  text-decoration: none;
  transition: color 0.3s;
}
.footer__box-link:hover {
  fill: #e1e1e1;
}
.footer__box-link:first-child {
  padding-left: 0;
}
.footer .dn {
  display: none;
}
.footer .phone-icon {
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  text-decoration: none;
  transition: color 0.3s;
}
.footer .phone-icon img {
  margin-right: 1em;
}
.footer__bottom-text {
  padding: 2em;
  text-align: center;
}
.footer__year {
  color: #fff;
  font-weight: 200;
}
.footer .bottom-logo {
  position: relative;
  transition: color 0.3s;
  font-weight: 200;
  z-index: 1;
  color: #fff;
  cursor: pointer;
}
.footer-p {
  line-height: 1.5em;
}

@media (min-width: 567px) {
  .header__arrow {
    bottom: 3em;
    left: 50%;
    padding: 2.1em;
  }
  .header__arrow-icon {
    width: 40px;
  }
  .achievments {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
  }
}
@media (min-width: 767px) {
  .header {
    background-image: url("/dist/img/header-img.avif");
    background-attachment: fixed;
  }
  .header__text {
    background: linear-gradient(90deg, #ffffff, #b0b0b0);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
    font-size: 3rem;
    font-weight: 700;
    z-index: 2;
  }
  .header__text .company-name {
    background: linear-gradient(90deg, #00a3e0, #005f8a);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 0.4em;
    font-size: 2.1em;
  }
  .header__text h1 {
    font-size: 3rem;
  }
  .mobile-underline {
    max-height: 81.2px;
  }
  .mobile-underline .hamburger {
    right: 100px;
  }
  .mobile-underline .hamburger--emphatic .hamburger-box {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 24px;
  }
  .mobile-underline .hamburger--emphatic .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    transition: transform 0.3s ease, background-color 0.3s ease, top 0.3s ease;
    position: absolute;
    width: 55px;
    height: 3px;
    border-radius: 4px;
    background-color: #8ACEF2;
  }
  .mobile-underline .hamburger--emphatic .hamburger-inner::before, .mobile-underline .hamburger--emphatic .hamburger-inner::after {
    width: 35px;
    height: 3px;
  }
  .mobile-underline .hamburger--emphatic .hamburger-inner::before {
    top: -14px;
  }
  .mobile-underline .hamburger--emphatic .hamburger-inner::after {
    bottom: -14px;
    right: 0;
  }
  .mobile-underline .hamburger.is-active .hamburger-inner::before, .mobile-underline .hamburger.is-active .hamburger-inner::after {
    width: 55px;
  }
  .mobile-underline .logo {
    top: 50%;
    left: 50px;
  }
  .hamburger:hover.hamburger--emphatic .hamburger-inner::before {
    width: 55px;
  }
  .hamburger:hover.hamburger--emphatic .hamburger-inner::after {
    width: 55px;
  }
  .nav-mobile .nav__link {
    font-size: 2.4rem;
    margin: 1rem 0;
  }
  .agency {
    padding: 5em 3em;
  }
  .agency .cards-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  .agency .cards-box .card {
    margin: 3rem;
  }
  .agency .cards-box .card__title {
    margin: 2rem 0;
  }
  .agency .cards-box .card .card-one,
  .agency .cards-box .card .card-two {
    grid-row: 1;
  }
  .about-us {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  .about-us-img {
    grid-column: 1/2;
    grid-row: 1/-1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .about-us__box {
    position: relative;
    grid-column: 2/3;
    grid-row: 1/-1;
    background: linear-gradient(90deg, #00a3e0, #005f8a);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-us__box .about-us-text {
    margin: 0 2em;
  }
  .about-us__box .about-us-text .about-us-title {
    font-size: 3rem;
    padding-top: 2em;
    margin-bottom: 0.5em;
  }
  .about-us__box .about-us-text .about-us-p {
    line-height: 3rem;
    padding-bottom: 2em;
  }
  .about-us__button {
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: 1em;
    padding: 1em 2em;
    background-color: transparent;
    border: 2px solid #fff;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    transition: color 0.3s, background-color 0.3s;
  }
  .about-us__button:hover {
    background-color: #fff;
    color: #25AAE1;
  }
  .offer .offer-box {
    grid-template-columns: repeat(2, 1fr);
  }
  .portfolio .projects {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .portfolio .projects .project-none-sm {
    display: block;
  }
  .portfolio .projects .project-none-md {
    display: none;
  }
  .contact__form-box:nth-child(1) {
    grid-column: 1/4;
    grid-row: 1/2;
    margin-right: 1.5em;
  }
  .contact__form-box:nth-child(2) {
    grid-column: 4/-1;
    grid-row: 1/3;
    margin-left: 1.5em;
  }
  .contact__form-box:nth-child(3) {
    grid-column: 1/-1;
    grid-row: 3/4;
  }
  .footer__boxes {
    display: flex;
    justify-content: space-between;
  }
  .footer__box-desktop {
    display: block;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .footer__box-desktop .contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    font-weight: 200;
    color: #fff;
  }
  .footer__box-desktop .contact-info img {
    margin-right: 1em;
  }
  .footer__box .contact-side-info {
    margin: 1em 0;
    text-align: left;
  }
  .footer__box .contact-side-info .admin-text {
    color: #dadada;
    margin: 0.5em 0;
    line-height: 3rem;
  }
  .footer__box .contact-side-info .admin-text .nip {
    color: #dadada;
    font-weight: 400;
  }
  .footer__box {
    margin: 0 2em;
    width: 25%;
  }
  .footer__box:first-child {
    width: 50%;
  }
  .footer .nav__link {
    display: flex;
    color: #fff;
  }
  .footer__box-title {
    font-size: 2.4rem;
  }
  .footer__box-list {
    list-style: none;
  }
  .footer .dn {
    display: block;
  }
  .footer .nav__link {
    margin-bottom: 1em;
    text-transform: uppercase;
    color: #8bbbfc;
    cursor: pointer;
    transition: transform 0.3s;
  }
  .footer .nav__link:hover {
    transform: translateY(-22.5%);
  }
}
@media (min-width: 992px) {
  .header__text h1 span {
    font-size: 3.4rem;
  }
  .achievments {
    background-attachment: fixed;
  }
  .cookie-notification {
    width: fit-content;
  }
  .cookie-notification .cookie-content {
    display: flex;
  }
  .reviews .reviews-title {
    font-size: 4rem;
  }
}
@media (min-width: 1024px) {
  .agency {
    padding: 5em 4em;
  }
  .portfolio .projects {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
@media (min-width: 1100px) {
  .mobile-underline {
    height: 81.2px;
    transition: background-color 0.3s;
  }
  .mobile-underline .hamburger,
  .mobile-underline .hamburger--emphatic {
    display: none;
  }
  .mobile-underline .logo {
    left: 10px;
  }
  .nav-mobile {
    display: none;
  }
  .nav-desktop {
    display: block;
    position: absolute;
    top: 50%;
    right: 4%;
    transform: translateY(-50%);
  }
  .nav-desktop .nav__link {
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    color: #f0f6f6;
    display: inline-block;
    padding: 0.7em;
    font-size: 2rem;
    transition: color 0.3s ease;
  }
  .nav-desktop .nav__link:hover {
    color: #25AAE1;
  }
}
@media (min-width: 1200px) {
  .achievments {
    grid-template-columns: repeat(4, 1fr);
  }
  .modal-content .modal-body .prev,
  .modal-content .modal-body .next {
    display: inline-block;
  }
  .modal-content .modal-body .prev {
    left: -6.3em;
  }
  .modal-content .modal-body .next {
    right: -6.3em;
  }
}
@media (min-width: 1400px) {
  .agency .cards-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, auto);
  }
  .offer .offer-box {
    grid-template-columns: repeat(3, 1fr);
  }
}