.subpage-title {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin: 0 0 1em;
	text-transform: uppercase;
	font-size: 4.5rem;
	text-transform: none;
	color: #000;
	font-weight: 500;
	font-family: $second-font;
	font-weight: 200;
	text-align: center;
	width: fit-content;
	z-index: 2;
}
.extra-span .error {
	font-size: 3.5rem;
}

.error {
	color: $dif-white;

	span {
		background: linear-gradient(90deg, #85b4fb, #b2e9ff);
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
		display: inline-block;
	}
}

.error-title {
	font-family: $second-font;
	font-size: 2rem;
	margin-bottom: 1rem;
	margin-left: -1em;
	margin: 0 1rem;
	font-weight: 500;
	line-height: 1.4em;
}

.slim-wrapper {
	margin: 0 auto;
	max-width: 850px;
	width: 100%;
}

.hosting-services {
	background-color: $white;
	position: relative;
	padding-top: 11em;

	h2 {
		font-weight: 400;
		font-size: 3rem;
		text-align: center;
	}

	.service-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(6, 1fr);
		column-gap: 1em;
		row-gap: 1em;

		.service {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			align-items: center;
			margin: 0 3em;
			padding: 1em;
			background-color: #fff;
			border-radius: 3px;
			box-shadow: 0 0px 8px rgba(0, 0, 0, 0.4);
			transition: transform 0.3s;
			z-index: 20;

			&:hover {
				transform: translateY(-5%);
			}

			h3 {
				margin-bottom: 0.7em;
				font-family: $main-font;
				color: $second;
			}

			.service-text {
				color: #444;
				font-family: $main-font;
			}
		}
	}

	.service-info {
		margin: 4em 0;
		text-align: center;
		color: #444;
		overflow: visible;

		p {
			font-size: 2.3rem;
			margin: 0 1.5em;
		}
	}
}

.intro {
	text-align: center;
	color: #444;
	font-size: 1.5rem;
	margin: -2em 1.5em 3em 1.5em;
}

//WEBSIDES

.websides-header,
.hosting-header,
.positioning-header,
.it-support-header,
.responsive-header,
.about-us-header,
.blog-header,
.cms-header,
.portfolio-header {
	position: relative;
	height: 55vh;
	background-position: center;
	background-size: cover;

	&__text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	&__text {
		display: inline-block;
		font-size: 2rem;
		font-weight: normal;
		z-index: 2;

		.company-name {
			font-family: $main-font;
			background: $blue-gradient;
			-webkit-background-clip: text;
			background-clip: text;
			color: transparent;
			display: inline-block;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 0.2em;
			font-size: 3.45rem;
		}

		h1 {
			font-size: 2.2rem;
			font-weight: 700;
			color: $dif-white;

			span {
				background: linear-gradient(90deg, #85b4fb, #b2e9ff);
				-webkit-background-clip: text;
				background-clip: text;
				color: transparent;
				display: inline-block;
			}
		}
	}
}

.websides-header {
	background-image: url('/dist/img/work-two-small.jpg');
}

.hosting-header {
	background-image: url('/dist/img/work-small.jpg');
}

.positioning-header {
	background-image: url('/dist/img/meeting-medium.jpg');
}

.it-support-header {
	background-image: url('/dist/img/coding-924920_640.avif');
}

.responsive-header {
	background-position: bottom;
	background-image: url('/dist/img/team-small.jpg');
}

.about-us-header {
	background-position: -20em -11em;
	background-image: url('/dist/img/abt-us-small.jpg');
}

.blog-header {
	height: 40vh;
	background: linear-gradient(120deg, rgba(0, 34, 56, 0.98), rgba(0, 0, 0, 0.98));

	.company-name {
		position: absolute;
		transform: translate(-50%, -50%);
		text-transform: none;
		font-size: 2.1rem;
		width: 60vw;
		font-weight: 500;
		color: $dif-white;
		display: inline-block;
	}
}

.portfolio-header {
	background-position: center;
	background-image: url('/dist/img/subpage-portfolio-bg-medium.jpg');

	.nav--scrolled {
		background: rgba(0, 0, 0, 0.7);
	}
}

#margin-botton-portfolio {
	margin-bottom: 7em;
}

.cms-header {
	height: 10vh;
	.nav--scrolled {
		background: linear-gradient(120deg, rgba(0, 14, 28, 0.98), rgba(28, 0, 0, 0.92));
	}
}

#contact-margin-blog {
	margin-top: 7em;

	.agency__button {
		left: 48%;
		bottom: 4em;
		transform: translateX(-50%);
	}
}

.blog-img {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 2em;
	width: 80%;
	border-radius: 2px;
	height: 300px;
	object-fit: cover;
}

.cards {
	display: grid;
	grid-template-columns: 1fr;
	border-top: 1px solid #939393;

	a {
		color: #00529e;
	}

	&__welcome {
		position: relative;
		text-align: center;
		padding-top: 4em;
		line-height: 2.5rem;

		&-heading {
			color: $second;
			font-family: $second-font;
			font-size: 3.5rem;
			line-height: 1.4em;
			margin: 0 0.5em;
			margin-bottom: 1rem;
			font-weight: 500;
		}
		&-text {
			font-family: $second-font;
			margin-top: 2em;
			font-size: 2rem;
			color: #555;
		}
	}

	.card {
		padding: 0 1.1em;
		// cursor: pointer;

		img {
			margin-top: 3em;
			width: 100%;
			height: 200px;
			object-fit: cover;
		}

		&__title {
			font-family: $main-font;
			margin-bottom: 0.5em;
		}

		p {
			font-family: $second-font;
			line-height: 1.4em;
			color: #333;
			cursor: pointer;
		}

		&:hover p {
			text-decoration: underline;
		}
	}
}

.p-none {
	display: none;
}

.webside-box {
	.websides-text {
		font-family: $second-font;
		color: #555;
		font-size: 1.7rem;
		line-height: 1.5em;
	}

	.agency__button {
		position: relative;
		top: 2em;
		left: 50%;
		transform: translateX(-58%);
	}
}

.subpage-accordion {
	background-color: $white;
	border-top: 1px solid #939393;

	.subpage-accordion {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		border-top: 1px solid $light;
		border-bottom: 1px solid $light;
		overflow: hidden;
	}

	.subpage-accordion-item {
		border-bottom: 1px solid $light;

		&:last-child {
			border-bottom: none;
		}

		&.active .subpage-accordion-content {
			padding: 15px 20px;
		}
	}

	.subpage-accordion-header {
		width: 100%;
		background: none;
		padding: 15px 20px;
		font-family: $main-font;
		font-size: 1.2em;
		font-weight: 300;
		color: #000;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		border: none;
		outline: none;
		text-align: left;
		transition: background 0.3s, color 0.3s;

		span {
			transition: color 0.3s;
		}

		&:hover {
			background: $light;
		}

		img {
			transition: transform 0.5s ease;
			filter: invert(0);
		}
	}

	.subpage-accordion-content {
		background: none;
		padding: 0 20px;
		font-family: $second-font;
		color: #444;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.5s ease-out, padding 0.5s ease-out;
		box-sizing: border-box;

		span {
			font-weight: 600;
		}
	}

	.subpage-accordion-item.active .subpage-accordion-content {
		max-height: 500px;
	}

	.subpage-accordion-item.active .subpage-accordion-header img {
		transform: rotate(180deg);
	}

	.subpage-accordion-item.active .subpage-accordion-header span {
		color: $second;
	}
}

.subpage-portfolio {
	background-color: #f0f6f6;
	border-bottom: 1px solid #939393;

	.projects {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(1, 1fr);
		gap: 20px;

		.project {
			display: flex;
			position: relative;
			background-color: $white;
			border: 1px solid #555;
			border-radius: 3px;
			overflow: hidden;
			box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
			cursor: pointer;
			transition: transform 0.3s, box-shadow 0.3s;

			&:hover {
				transform: scale(1.05);
				box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
			}

			.project-image {
				width: 100%;
				height: auto;
				object-fit: cover;
				transition: opacity 0.3s;
				// border-bottom: 4px solid $extra;
				cursor: zoom-in;
			}

			.project-overlay {
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: rgba(0, 0, 0, 0.6);
				color: $white;
				padding: 20px;
				text-align: center;
				opacity: 0;
				transition: opacity 0.3s;

				.project-name {
					font-size: 1.25rem;
					margin-bottom: 10px;
				}

				.link-box {
					display: flex;
				}

				.project-link {
					display: inline-block;
					position: relative;
					padding: 10px 20px;
					margin: 0 auto;
					text-decoration: none;
					text-transform: uppercase;
					border-radius: 5px;
					font-weight: bold;
					color: $white;
					transition: transform 0.3s;

					&:hover {
						transform: translateY(-2px);
					}
				}
			}

			&:hover .project-overlay {
				opacity: 1;
			}
		}

		.project-none-sm {
			display: none;
		}
	}
}

.reasons-heading {
	text-align: center;
	color: #2c3e50;
	font-family: $main-font;
	font-size: 3rem;
	font-weight: 500;
	margin-bottom: 30px;

	.span {
		font-weight: 700;
		font-size: 4rem;
		background: $blue-gradient;
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
		display: inline-block;
	}
}

.reason-h4 {
	color: #2c3e50;
	font-family: $main-font;
	font-size: 2.2rem;
	font-weight: 500;
	margin-top: 2em;
}

.section-photo {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	object-fit: cover;
	width: 1000px;
	height: 400px;
}

.reason-text {
	.reason-li {
		margin: 3.2em 0;
	}
}

.reason,
.write-to-us {
	margin-bottom: 30px;
	padding: 20px;

	h3 {
		font-family: $main-font;
		color: #1b2630;
		font-weight: 500;
		font-size: 2.5rem;
		margin-bottom: 15px;
	}

	&-text {
		font-family: $second-font;
		color: #555;
		line-height: 1.8;
		font-size: 16px;
	}
}

.write-to-us {
	text-align: center;

	a {
		color: $second;
		text-decoration: underline;
	}
}

.extra-span {
	font-weight: 600;
	background: $blue-gradient;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}

span {
	color: #444;
	font-weight: 600;
}

.more {
	position: relative;
	background-image: url('/dist/img/luca-bravo-small.jpg');
	background-size: cover;

	img {
		z-index: 2;
	}

	.options {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, 1fr);
		text-align: center;
		z-index: 2;

		.more-option {
			padding: 0 3em;
			z-index: 2;

			img {
				margin: 1em 0;
			}

			&--title {
				color: #f0f6f6;
				margin-bottom: 0.5em;
			}

			&--text {
				color: #f0f6f6;
			}
		}
	}

	.card__button {
		border: 2px solid $white;
		color: $white;
		transition: background-color 1s;

		&:hover {
			background-color: #70b5ff;
			border-color: #70b5ff;
			color: #fff;
			box-shadow: 0 5px 15px rgba(0, 162, 255, 0.4);
		}
	}
}
.contact {
	border-top: 1px solid #939393;
}

//***********************
//********SMALL**********
//***********************

@media (min-width: 600px) {
	.cards-box {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.blog-header {
		.company-name {
			font-size: 2.5rem;
		}
	}
}

//***********************
//*******MEDIUM**********
//***********************

@media (min-width: 767px) {
	.error {
		font-size: 6rem;
	}

	.error-title {
		font-size: 3rem;
	}

	.websides-header {
		background-image: url('/dist/img/work-two-big.jpg');
	}

	.hosting-header {
		background-image: url('/dist/img/work-big.jpg');
	}

	.positioning-header {
		background-image: url('/dist/img/meeting-big.jpg');
	}

	.it-support-header {
		background-image: url('/dist/img/coding-big.jpg');
	}

	.responsive-header {
		background-image: url('/dist/img/team-big.jpg');
	}

	.about-us-header {
		background-image: url('/dist/img/abt-us-big.jpg');
		background-position: 0 37em;
	}

	.blog-header {
		.company-name {
			font-size: 2.9rem;
		}
	}

	.p-none {
		display: block;
	}

	.subpage-underline {
		height: 11.5%;

		.hamburger {
			right: 100px;

			&--emphatic .hamburger-box {
				display: inline-block;
				position: relative;
				width: 40px;
				height: 24px;
			}

			&--emphatic .hamburger-inner {
				display: block;
				top: 50%;
				margin-top: -2px;
				transition: transform 0.3s ease, background-color 0.3s ease, top 0.3s ease;
				position: absolute;
				width: 55px;
				height: 3px;
				border-radius: 4px;
				background-color: $extra;

				&::before,
				&::after {
					width: 35px;
					height: 3px;
				}

				&::before {
					top: -14px;
				}

				&::after {
					bottom: -14px;
					right: 0;
				}
			}

			&.is-active .hamburger-inner {
				&::before,
				&::after {
					width: 55px;
				}
			}
		}

		.logo {
			top: 50%;
			left: 50px;
		}
	}

	.hamburger:hover.hamburger--emphatic .hamburger-inner::before {
		width: 55px;
	}
	.hamburger:hover.hamburger--emphatic .hamburger-inner::after {
		width: 55px;
	}

	.hosting-services {
		.service-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(3, 1fr);
			column-gap: 1em;
			row-gap: 1em;

			.service {
				margin: 0 0;
			}
		}
	}
	.intro {
		font-size: 1.6rem;
	}

	.reasons-heading {
		font-size: 3.5rem;
	}

	.subpage-portfolio {
		.projects {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 1fr);
		}
	}

	.more {
		background-image: url('/dist/img/luca-bravo-big.jpg');

		.options {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
		}
	}
}

//***********************
//********LARGE**********
//***********************

@media (min-width: 992px) {
	.websides-header,
	.hosting-header,
	.positioning-header,
	.it-support-header,
	.responsive-header,
	.about-us-header,
	.blog-header,
	.cms-header,
	.portfolio-header,
	.more {
		background-attachment: fixed;
	}

	.hosting-header {
		&__text {
			p {
				span {
					font-size: 3.4rem;
				}
			}
		}
	}

	.blog-header {
		.company-name {
			font-size: 4rem;
		}
	}

	.subpage-underline {
		height: 10%;
		transition: background-color 0.3s;
		.hamburger,
		.hamburger--emphatic {
			display: none;
		}

		.logo {
			left: 10px;
		}
	}
}

@media (min-width: 1024px) {
	.cards-box {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
