$main: #25AAE1;
$second: #0185BC;
$extra: #8ACEF2;
$white: #fff;
$light: #e1e1e1;
$grey: #bcbdc0;
$dif-white: #f0f6f6;

$blue-gradient: linear-gradient(90deg, #00a3e0, #005f8a);
$white-grandient: linear-gradient(90deg, #ffffff, #b0b0b0); 

$main-font: "Poppins", sans-serif;
$second-font: "Montserrat", sans-serif;