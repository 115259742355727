@import './colors';
@import './animations';
@import './subpages';

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	scroll-padding-top: 81.2px;
	scroll-behavior: smooth; //!!!
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 1.6rem;
}

section {
	overflow: hidden;
}

a {
	text-decoration: none;
}

.wrapper {
	margin: 0 auto;
	max-width: 1200px;
	width: 100%;
}

.extra-wrapper {
	margin: 0 auto;
	max-width: 1400px;
	width: 100%;
}

.nav-scrolled {
	background-color: rgba(0, 0, 0, 0.7);
	transition: 0.3s ease-in-out;
}

.section-title {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin: 0 0 2em;
	text-transform: uppercase;
	font-size: 4.5rem;
	background: $blue-gradient;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	display: inline-block;
	font-weight: 200;
	width: fit-content;
}

.section {
	padding: 6em 2em;
}

.hero-shadow {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1;
}

.header {
	position: relative;
	height: 100vh;
	background-image: url('/dist/img/laptop-small.jpg');
	background-position: center;
	background-size: cover;
	// z-index: -2;

	&__text {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	&__text {
		display: inline-block;
		font-size: 2rem;
		font-weight: normal;
		z-index: 2;

		.company-name {
			font-family: $main-font;
			background: $blue-gradient;
			-webkit-background-clip: text;
			background-clip: text;
			color: transparent;
			display: inline-block;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 0.2em;
			font-size: 4rem;
		}

		h1 {
			font-size: 2.2rem;
			font-weight: 700;
			color: $dif-white;

			span {
				background: linear-gradient(90deg, #85b4fb, #b2e9ff);
				-webkit-background-clip: text;
				background-clip: text;
				color: transparent;
				display: inline-block;
			}
		}
	}

	&__arrow {
		position: absolute;
		bottom: 5em;
		left: 50%;
		padding: 2.1em;
		transform: translateX(-50%);
		border: 1px solid $white;
		border-radius: 50%;
		z-index: 30;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			width: 100%;
			height: 100%;
			border: 1px solid $white;
			border-radius: 50%;
			animation: pulse 2s infinite;
		}

		&-icon {
			display: block;
			width: 30px;
		}
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.mobile-underline {
	position: fixed;
	height: 81.2px;
	width: 100%;
	z-index: 20000;
	transition: 0.3s;

	.hamburger {
		position: absolute;
		padding: 15px;
		top: 50%;
		transform: translateY(-50%);
		right: 30px;
		display: inline-block;
		cursor: pointer;
		z-index: 1000;
		-webkit-touch-callout: none;
		user-select: none;
		background: none;
		border: none;
		z-index: 2000;

		&--emphatic .hamburger-box {
			display: inline-block;
			position: relative;
			width: 40px;
			height: 24px;
		}

		&--emphatic .hamburger-inner {
			display: block;
			top: 50%;
			margin-top: -2px;
			transition: transform 0.3s ease, background-color 0.3s ease, top 0.3s ease;
			position: absolute;
			width: 40px;
			height: 3px;
			border-radius: 4px;
			background-color: $extra;

			&::before,
			&::after {
				content: '';
				display: block;
				background-color: $extra;
				transition: transform 0.3s ease, top 0.3s ease, opacity 0.3s ease;
				position: absolute;
				width: 40px;
				height: 3px;
				border-radius: 4px;
				transition: 0.3s;
			}

			&::before {
				top: -13.5px;
			}

			&::after {
				bottom: -13.5px;
				right: 0;
			}
		}

		&.is-active .hamburger-inner {
			background-color: transparent;

			&::before {
				top: 0;
				width: 40px;
				transform: rotate(45deg);
			}

			&::after {
				top: 0;
				width: 40px;
				transform: rotate(-45deg);
			}
		}
	}

	.logo {
		position: absolute;
		top: 50%;
		left: -30px;
		transform: scale(0.7);
		transform: translateY(-50%);
		z-index: 2000;
	}
}

.hamburger:hover.hamburger--emphatic .hamburger-inner::before {
	width: 40px;
}
.hamburger:hover.hamburger--emphatic .hamburger-inner::after {
	width: 40px;
}

.nav {
	&-mobile {
		position: fixed;
		height: 100vh;
		width: 100vw;
		background: linear-gradient(120deg, rgba(0, 14, 28, 0.98), rgba(17, 0, 0, 0.98));
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;
		transform: translateX(102%);
		transition: transform 0.3s;
		z-index: 1000;

		.nav__link {
			display: block;
			position: relative;
			text-transform: uppercase;
			font-weight: 700;
			text-decoration: none;
			color: $dif-white;
			display: inline-block;
			padding: 0.7em 0;
			font-size: 2rem;
			transition: color 0.3s ease;

			&:hover {
				color: $extra;
			}
		}

		.nav__translate {
			padding: 1em 2em;
		}

		&--active {
			transform: translateX(0);
		}
	}

	&-desktop {
		display: none;
	}
}

.translate-box {
	position: relative;
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: .8em;
	bottom: 0.2em;
}


.goog-te-gadget-icon {
	display: none;
}

.goog-te-gadget-simple {
	background-color: transparent !important;
	border: 1px solid transparent !important;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
	font-family: $second-font;
	color: #f5f5f5;
	font-weight: 400;
}

.nav__flags {
	display: flex;
	align-items: center;
	margin-left: auto;

	.flag-button {
		cursor: pointer;
		margin-left: 10px;
		width: 32px;
		height: 32px;
		// display: flex;
		align-items: center;

		// img {
		// 	// width: 100%;
		// 	// height: auto;
		// 	// display: block;
		// }
	}
}
.agency {
	padding: 5em 2em;

	&__text {
		position: relative;
		text-align: center;
		padding-bottom: 6rem;
		line-height: 2.5rem;

		.agency-title {
			font-family: $second-font;
			font-size: 2.5rem;
			margin-bottom: 1rem;
			margin-left: -1em;
			font-weight: 500;

			.congrats {
				font-size: 4rem;
				line-height: 1.5em;
				font-weight: 700;
			}
		}

		&-p {
			font-size: 1.5rem;
			color: #555;
			.agency-p-span {
				font-weight: 700;
			}
		}
	}

	.cards-box {
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		.card {
			text-align: center;
			margin: 3rem 0;
			flex: 1;

			&__title {
				margin: 1rem 0;
				font-size: 2.5rem;
			}

			p {
				color: #555;
			}
		}
	}
}

.agency__button {
	position: relative;
	font-family: $second-font;
	font-weight: 500;
	margin: 20px;
	padding: 1em 2em;
	background: none;
	border: 2px solid $second;
	color: $second;
	font-size: 1.3rem;
	text-transform: uppercase;
	cursor: pointer;
	overflow: hidden;
}

.agency__button::before {
	position: absolute;
	content: '';
	top: -30px;
	left: -130px;
	height: 260%;
	width: 100px;
	background: linear-gradient(90deg, rgba(0, 163, 224, 0.8), rgba(0, 95, 138, 0.1));
	transform: rotate(20deg);
	transition: 0.25s;
}

.agency__button:hover::before {
	left: 130%;
}

.card__button {
	margin-top: 2em;
	padding: 0.7em 3em;
	background-color: transparent;
	border: 2px solid $second;
	font-family: $main-font;
	cursor: pointer;
	color: $second;
	text-transform: uppercase;
	transition: color 0.3s, background-color 0.3s;

	&:hover {
		background-color: $second;
		color: $white;
	}
}

.accordion {
	background-color: $dif-white;

	.section-title {
		text-transform: none;
		background: linear-gradient(120deg, rgba(0, 14, 28, 0.98), rgba(55, 0, 0, 0.98));
		font-weight: 500;
		font-family: $second-font;
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
		display: inline-block;
		text-align: center;
	}

	.accordion {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		border-top: 1px solid #555;
		border-bottom: 1px solid #555;
		overflow: hidden;
	}

	.accordion-item {
		border-bottom: 1px solid #555;

		&:last-child {
			border-bottom: none;
		}

		&.active .accordion-content {
			padding: 15px 20px;
		}
	}

	.accordion-header {
		width: 100%;
		background: none;
		padding: 15px 20px;
		font-family: $main-font;
		font-size: 1.2em;
		font-weight: 300;
		color: #000;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		border: none;
		outline: none;
		text-align: left;
		transition: background 0.3s, color 0.3s;

		span {
			transition: color 0.3s;
		}

		&:hover {
			background: $light;
		}

		img {
			transition: transform 0.3s ease;
			filter: invert(0);
		}
	}

	.accordion-content {
		background: none;
		padding: 0 20px;
		font-family: $second-font;
		color: #444;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-out, padding 0.3s ease-out;
		box-sizing: border-box;

		p {
			line-height: 1.5em;
		}
	}

	.accordion-item.active .accordion-content {
		max-height: 500px;
	}

	.accordion-item.active .accordion-header img {
		transform: rotate(180deg);
	}

	.accordion-item.active .accordion-header span {
		color: $second;
	}

	&__text {
		font-family: $main-font;
		margin-top: 4em;
		text-align: center;

		&-heading {
			font-size: 3rem;
			font-weight: 500;
			margin-bottom: 0.5em;
		}

		&-p {
			line-height: 1.6em;
		}
	}
}

.offer {
	background-color: $white;

	.offer-box {
		display: grid;
		gap: 1.5em;

		.offer-card {
			text-align: center;
			padding: 1em;
			background-color: $white;
			border-radius: 3px;
			box-shadow: 0 0px 8px rgba(0, 0, 0, 0.4);
			transition: transform 0.3s;

			&:hover {
				transform: scale(0.95);
			}

			img {
				max-width: 60px;
				margin-bottom: 0.5em;
			}

			.offer-card__title {
				font-size: 1.2em;
				margin-bottom: 0.5em;
				color: $second;
			}

			p {
				font-size: 1em;
				color: #555;
			}
		}
	}
}

.achievments {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-around;
	background-image: url('/dist/img/achievments-medium.jpg');
	width: 100%;
	background-position: center;
	background-size: cover;
	&__box {
		display: flex;
		flex-direction: column;
		text-align: center;
		margin: 1em 0;

		.achievment {
			font-family: $main-font;
			font-size: 4.5rem;
			background: $blue-gradient;
			-webkit-background-clip: text;
			background-clip: text;
			color: transparent;
			display: inline-block;
			margin-bottom: -0.1em;
			z-index: 2;
		}

		&--text {
			font-family: $second-font;
			font-size: 2.5rem;
			margin: 1em;
			font-weight: 700;
			color: $white;
			z-index: 2;
		}
	}

	.dark-shadow {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 1;
	}
}

.portfolio {
	background-color: $dif-white;

	.filter-buttons {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px;
		margin-bottom: 30px;

		.filter-btn {
			padding: 1em 1.5em;
			background-color: transparent;
			border: 2px solid $second;
			cursor: pointer;
			color: $second;
			font-size: 1.3rem;
			transition: transform 0.3s;

			&:hover {
				// background-color: $secondary;
				// color: $white;
				transform: scale(0.95);
			}
		}
	}

	.projects {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(1, 1fr);
		gap: 20px;

		.project {
			display: flex;
			position: relative;
			background-color: $white;
			border: 1px solid #a3a3a3;
			border-radius: 3px;
			overflow: hidden;
			box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
			cursor: pointer;
			transition: transform 0.3s, box-shadow 0.3s;

			&:hover {
				transform: scale(1.05);
				box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
			}

			.project-image {
				width: 100%;
				height: auto;
				object-fit: cover;
				transition: opacity 0.3s;
				cursor: zoom-in; //JESZCZE ZOBACZYMY
			}

			.project-overlay {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background: rgba(0, 0, 0, 0.6);
				color: $white;
				padding: 20px;
				text-align: center;
				transform: translateY(100%);
				// opacity: 0;
				transition: transform 0.3s;
				border-top: 2px solid $extra;

				.project-name {
					font-size: 1.25rem;
					margin-bottom: 10px;
				}

				.link-box {
					display: flex;
				}

				.project-link {
					display: inline-block;
					position: relative;
					padding: 10px 20px;
					margin: 0 auto;
					text-decoration: none;
					text-transform: uppercase;
					border-radius: 5px;
					font-weight: bold;
					color: $white;
					transition: transform 0.3s;

					&:hover {
						transform: translateY(-2px);
					}
				}
			}

			&:hover .project-overlay {
				transform: translateY(0);
				// opacity: 1;
			}
		}

		.project-none-sm {
			display: none;
		}
	}
}

.load-more {
	display: inline-block;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 5em;
	padding: 0.7em 3em;
	background-color: transparent;
	border: 2px solid $second;
	font-family: $main-font;
	cursor: pointer;
	color: $second;
	text-transform: uppercase;
	transition: color 0.3s, background-color 0.3s;

	&:hover {
		background-color: $second;
		color: $white;
	}

	&:hover {
		background-color: $second;
	}
}

.modal {
	display: none;
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.8); // Dark background with transparency
	justify-content: center;
	align-items: center;

	&-content {
		// background-color: #fff;
		margin: auto;
		padding: 20px;
		border-radius: 10px;
		width: 100%;
		max-width: 1000px;
		// box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
		position: relative;
		text-align: center;

		.close {
			opacity: 0;
		}

		.modal-body {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			.prev,
			.next {
				display: none;
				position: absolute;
				background: transparent;
				border: none;
				cursor: pointer;
				padding: 0.7em 0.8em;
				background-color: rgba(255, 255, 255, 0.8);
				border-radius: 50%;

				img {
					width: 3.4em;
					height: 3.4em;
				}
			}

			.prev {
				left: 0;
				z-index: 1;
				img {
					transform: translate(-5%, 2%);
				}
			}

			.next {
				right: 0;
				img {
					transform: translate(5%, 2%);
				}
			}

			.modal-images {
				max-width: 100%;
				max-height: 100%;
				img {
					width: 100%;
					height: auto;
					border-radius: 0.1em;
					transform: scale(1.01);
				}
			}
		}
	}
}

.reviews {
	padding: 3.2em 1em;
	border-top: 1px solid #939393;

	.reviews-title {
		text-align: center;
		font-family: $main-font;
		padding-bottom: 1em;
		font-size: 3rem;
		font-weight: 400;
	}
}

.contact {
	background-color: $dif-white;

	&__text {
		font-family: $main-font;
		color: #555;
		font-size: 2rem;
		text-align: center;
		margin-bottom: 5em;
	}

	&__form {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
	}

	&__form-box {
		label {
			display: block;
			margin-bottom: 0.5em;
			font-size: 2rem;
		}

		textarea {
			max-width: 100%;
			width: 100%;
			min-width: 100%;
		}

		input,
		textarea {
			padding: 1em 2em;
			margin-bottom: 2em;
			background-color: #f5f5f5;
			width: 100%;
			color: #000;
			border: 1px solid rgba(0, 0, 0, 0.3);
			border-radius: 1em;

			&:focus {
				outline: none;
				border: 1px solid $main;
			}
		}

		&:nth-child(1) {
			grid-column: 1/-1;
			grid-row: 1/2;
		}
		&:nth-child(2) {
			grid-column: 1/-1;
			grid-row: 2/3;
		}
		&:nth-child(3) {
			grid-column: 1/-1;
			grid-row: 3/4;
		}
	}

	.checkbox {
		grid-row: 4/5;
		grid-column: 1/-1;
		z-index: 1;
		input {
			padding: 1em;
			cursor: pointer;
			margin-right: 1em;
		}

		label {
			font-family: $main-font;
			line-height: 3rem;
			color: #555;
		}
	}

	&__form-btn {
		grid-column: 1/3;
		grid-row: 6/7;
		padding: 1em;
		margin-top: 1em;
		margin-bottom: 2em;
		border: 1px solid $main;
		background: none;
		font-weight: bold;
		color: $main;
		cursor: pointer;
		transition: background-color 0.3s, color 0.3s;

		p {
			display: flex;
			justify-content: center;
		}

		&:hover,
		&:focus {
			outline: none;
			color: $white;
			background-color: $main;
		}
	}

	.side-info {
		grid-column: 1/-1;
		grid-row: 5/6;
		margin: 1em 0;
		text-align: center;
		color: #555;

		p {
			margin: 3em 0;
			line-height: 3rem;
		}
	}
}

.cookie-notification {
	position: fixed;
	bottom: 1em;
	left: 50%;
	transform: translatex(-50%);
	background: rgba(0, 0, 0, 0.6);
	box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
	font-family: $main-font;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80vw;
	padding: 0.8em 2em;
	border-radius: 0.3em;
	z-index: 1000;
	transition: transform 0.3s ease;

	&.hidden {
		display: none;
	}

	.cookie-content {
		max-width: 900px;
		text-align: center;

		p {
			margin: 10px 0 10px;
			font-size: 16px;
			color: $white;
		}

		.cookie-button {
			background: $second;
			border: none;
			border-radius: 0.3em;
			color: $white;
			font-family: $second-font;
			padding: 10px 20px;
			margin: 0 1em;
			font-size: 16px;
			cursor: pointer;
			transition: background 0.3s ease;

			&:hover {
				background: $second;
			}
		}
	}
}

.footer {
	background: linear-gradient(120deg, rgba(0, 14, 28, 0.98), rgba(17, 0, 0, 0.98));

	.wrapper {
		padding: 6em 2em 2em;
	}

	&__box {
		font-size: 1.4rem;

		&-title {
			background: $blue-gradient;
			-webkit-background-clip: text;
			background-clip: text;
			color: transparent;
			display: inline-block;
			margin-bottom: 1em;
			font-size: 3rem;
			text-transform: uppercase;
		}

		.contact-side-info {
			.admin-text {
				.nip {
					color: #fff;
					font-weight: 400;
				}
			}
		}
	}

	p {
		color: #fff;
	}

	&__box-desktop {
		display: none;
	}

	&__box-social {
		margin: 2em 1em;
	}

	&__box-link {
		padding: 0 0.3em;
		font-size: 3.6rem;
		text-decoration: none;
		transition: color 0.3s;

		&:hover {
			fill: $light;
		}

		&:first-child {
			padding-left: 0;
		}
	}

	.dn {
		display: none;
	}

	.phone-icon {
		padding: 0;
		margin: 0;
		font-size: 1.6rem;
		text-decoration: none;
		transition: color 0.3s;

		img {
			margin-right: 1em;
		}
	}

	&__bottom-text {
		padding: 2em;
		text-align: center;
	}

	&__year {
		color: $white;
		font-weight: 200;
	}

	.bottom-logo {
		position: relative;
		transition: color 0.3s;
		font-weight: 200;
		z-index: 1;
		color: $white;
		cursor: pointer;
	}

	&-p {
		line-height: 1.5em;
	}
}

//***********************
//********SMALL**********
//***********************

@media (min-width: 567px) {
	.header {
		&__arrow {
			bottom: 3em;
			left: 50%;
			padding: 2.1em;

			&-icon {
				width: 40px;
			}
		}
	}

	.achievments {
		display: grid;
		grid-template-columns: 1fr 1fr;
		text-align: center;
	}
}

//***********************
//*******MEDIUM**********
//***********************

@media (min-width: 767px) {
	.header {
		background-image: url('/dist/img/rsz_1laptop-big.jpg');
		background-attachment: fixed;
		&__text {
			background: $white-grandient;
			-webkit-background-clip: text;
			background-clip: text;
			color: transparent;
			display: inline-block;
			font-size: 3rem;
			font-weight: 700;
			z-index: 2;

			.company-name {
				background: $blue-gradient;
				-webkit-background-clip: text;
				background-clip: text;
				color: transparent;
				display: inline-block;
				text-transform: uppercase;
				margin-bottom: 0.4em;
				font-size: 2.1em;
			}

			h1 {
				font-size: 3rem;
			}
		}
	}

	.mobile-underline {
		max-height: 81.2px;

		.hamburger {
			right: 100px;

			&--emphatic .hamburger-box {
				display: inline-block;
				position: relative;
				width: 40px;
				height: 24px;
			}

			&--emphatic .hamburger-inner {
				display: block;
				top: 50%;
				margin-top: -2px;
				transition: transform 0.3s ease, background-color 0.3s ease, top 0.3s ease;
				position: absolute;
				width: 55px;
				height: 3px;
				border-radius: 4px;
				background-color: $extra;

				&::before,
				&::after {
					width: 35px;
					height: 3px;
				}

				&::before {
					top: -14px;
				}

				&::after {
					bottom: -14px;
					right: 0;
				}
			}

			&.is-active .hamburger-inner {
				&::before,
				&::after {
					width: 55px;
				}
			}
		}

		.logo {
			top: 50%;
			left: 50px;
		}
	}

	.hamburger:hover.hamburger--emphatic .hamburger-inner::before {
		width: 55px;
	}
	.hamburger:hover.hamburger--emphatic .hamburger-inner::after {
		width: 55px;
	}

	.nav-mobile .nav__link {
		font-size: 2.4rem;
		margin: 1rem 0;
	}

	.agency {
		padding: 5em 3em;

		.cards-box {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, auto);

			.card {
				margin: 3rem;

				&__title {
					margin: 2rem 0;
				}

				.card-one,
				.card-two {
					grid-row: 1;
				}
			}
		}
	}

	.about-us {
		display: grid;
		position: relative;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;

		&-img {
			grid-column: 1/2;
			grid-row: 1/-1;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&__box {
			position: relative;
			grid-column: 2/3;
			grid-row: 1/-1;
			background: $blue-gradient;
			color: $white;
			display: flex;
			align-items: center;
			justify-content: center;

			.about-us-text {
				margin: 0 2em;

				.about-us-title {
					font-size: 3rem;
					padding-top: 2em;
					margin-bottom: 0.5em;
				}

				.about-us-p {
					line-height: 3rem;
					padding-bottom: 2em;
				}
			}
		}

		&__button {
			margin-top: 2em;
			margin-bottom: 2em;
			margin-left: 1em;
			padding: 1em 2em;
			background-color: transparent;
			border: 2px solid $white;
			font-family: $main-font;
			cursor: pointer;
			color: $white;
			text-transform: uppercase;
			transition: color 0.3s, background-color 0.3s;

			&:hover {
				background-color: $white;
				color: $main;
			}
		}
	}

	.offer {
		.offer-box {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.portfolio {
		.projects {
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

			.project-none-sm {
				display: block;
			}
			.project-none-md {
				display: none;
			}
		}
	}

	.contact {
		&__form-box {
			&:nth-child(1) {
				grid-column: 1/4;
				grid-row: 1/2;
				margin-right: 1.5em;
			}
			&:nth-child(2) {
				grid-column: 4/-1;
				grid-row: 1/3;
				margin-left: 1.5em;
			}
			&:nth-child(3) {
				grid-column: 1/-1;
				grid-row: 3/4;
			}
		}
	}

	.footer {
		&__boxes {
			display: flex;
			justify-content: space-between;
		}

		&__box-desktop {
			display: block;
			display: flex;
			flex-direction: column;
			align-self: center; // JAK SIĘ USUNIE, TO BĘDZIE RÓWNOLEGLE Z PIERWSZYM BOXEM

			.contact-info {
				display: flex;
				align-items: center;
				margin-bottom: 1em;
				font-weight: 200;
				color: $white;

				img {
					margin-right: 1em;
				}
			}
		}

		&__box {
			.contact-side-info {
				margin: 1em 0;
				text-align: left;

				.admin-text {
					color: #dadada;
					margin: 0.5em 0;
					line-height: 3rem;
					//PADDING TOP ZOSTAŁ NADANY W HTML W ATRYBUCIE STYLE !!(TYLKO TAM JEST UŻYTY STYLE)!!

					.nip {
						color: #dadada;
						font-weight: 400;
					}
				}
			}
		}

		&__box {
			margin: 0 2em;
			width: 25%;

			&:first-child {
				width: 50%;
			}
		}

		.nav__link {
			display: flex;
			color: $white;
		}

		&__box-title {
			font-size: 2.4rem;
		}

		&__box-list {
			list-style: none;
		}

		.dn {
			display: block;
		}

		.nav__link {
			margin-bottom: 1em;
			text-transform: uppercase;
			color: #8bbbfc;
			cursor: pointer;
			transition: transform 0.3s;

			&:hover {
				transform: translateY(-22.5%);
			}
		}
	}
}

//***********************
//********LARGE**********
//***********************

@media (min-width: 992px) {
	.header {
		&__text {
			h1 {
				span {
					font-size: 3.4rem;
				}
			}
		}
	}

	.achievments {
		background-attachment: fixed;
	}

	.cookie-notification {
		width: fit-content;
		.cookie-content {
			display: flex;
		}
	}

	.reviews {
		.reviews-title {
			font-size: 4rem;
		}
	}
}

@media (min-width: 1024px) {
	.agency {
		padding: 5em 4em;
	}

	.portfolio {
		.projects {
			grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		}
	}
}

@media (min-width: 1100px) {
	.mobile-underline {
		height: 81.2px;
		transition: background-color 0.3s;
		.hamburger,
		.hamburger--emphatic {
			display: none;
		}

		.logo {
			left: 10px;
		}
	}

	.nav-mobile {
		display: none;
	}

	.nav-desktop {
		display: block;
		position: absolute;
		top: 50%;
		right: 4%;
		transform: translateY(-50%);

		.nav__link {
			text-transform: uppercase;
			font-weight: 700;
			text-decoration: none;
			color: $dif-white;
			display: inline-block;
			padding: 0.7em;
			font-size: 2rem;
			transition: color 0.3s ease;

			&:hover {
				color: $main;
			}
		}
	}
}

@media (min-width: 1200px) {
	.achievments {
		grid-template-columns: repeat(4, 1fr);
	}

	.modal {
		&-content {
			.modal-body {
				.prev,
				.next {
					display: inline-block;
				}

				.prev {
					left: -6.3em;
				}

				.next {
					right: -6.3em;
				}
			}
		}
	}
}

@media (min-width: 1400px) {
	.agency {
		.cards-box {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(1, auto);
		}
	}

	.offer {
		.offer-box {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}